<template>
    <el-dialog title="设置登录帐户" :visible.sync="accountFormVisible" width="450px" @close="resetForm('accountForm')">
        <el-form :model="accountForm" :rules="accountRules" ref="accountForm" size="mini" label-width="80px">
            <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="accountForm.oldPassword" show-password type="password" :minlength="6"
                    style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="accountForm.newPassword" show-password type="password" :minlength="6"
                    style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" :minlength="6" prop="confirmPassword">
                <el-input type="password" v-model="accountForm.confirmPassword" show-password autocomplete="off"
                    style="width:200px"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('accountForm')">重置</el-button>
            <el-button type="primary" @click="handleAccountSubmit('accountForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ChangePwd',
    data() {
         //校验数字
         const regNumber = /(?=.*[\d])/;
        // 校验字母
        const regLetter = /(?=.*[a-zA-Z])/;
        // 校验特殊字符
        const regCharacter = /(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、])/;
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (value.length < 6) {
                    callback(new Error('密码不能小与6位'))
                } else {
                    if (!regNumber.test(value)) {
                        callback(new Error('密码必须由数字，英文字母和特殊字符组成!'))
                    } else if (!regLetter.test(value)) {
                        callback(new Error('密码必须由数字，英文字母和特殊字符组成!'))
                    } else if (!regCharacter.test(value)) {
                        callback(new Error('密码必须由数字，英文字母和特殊字符组成!'))
                    } else if(this.accountForm.confirmPassword !== ""){
                        this.$refs.accountForm.validateField("confirmPassword");
                    }
                }
                // if (this.accountForm.confirmPassword !== "") {
                //     this.$refs.accountForm.validateField("confirmPassword");
                // }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.accountForm.newPassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            accountForm: {
                newPassword: "",
                confirmPassword: "",
                oldPassword: ""
            },
            accountFormVisible: false,
            accountRules: {
                oldPassword: [
                    { required: true, message: "请输入旧密码", trigger: "blur" },
                    { min: 6, max: 45, message: "长度在 6 到 45 个字符", trigger: "blur" }
                ],
                newPassword: [
                    { validator: validatePass, trigger: "blur" },
                    { min: 6, max: 45, message: "长度在 6 到 45 个字符", trigger: "blur" }
                ],
                confirmPassword: [{ validator: validatePass2, trigger: "blur" }]
            }
        }
    },
    methods: {
        handleAccountSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.$api.changePassword(this.accountForm).then(res => {
                        if (res.code == 200) {
                            this.$message.success("密码修改成功");
                        } else {
                            this.$message.error(res.msg || "ERROR!");
                        }
                        this.accountFormVisible = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>